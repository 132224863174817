<script>
import ApiService from "@services/api.service";

export default {
  name: "LeaveReport",
  data() {
    return {
      loader: {
        page: false,
        button: false
      },
      filter: {
        dateFrom: null,
        dateTo: null
      }
    };
  },
  computed: {},
  methods: {
    generate() {
      this.loader.button = true;
      ApiService.downloadPost("/Report/leave", this.filter)
        .then(resp => {
          this.download(resp.data, "LeaveReport.xlsx");
        })
        .catch(err => {
          this.showToast("error", err.message);
        })
        .finally(() => {
          this.loader.button = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-overlay :show="loader.button">
        <b-form @submit.stop.prevent="generate">
          <b-row>
            <b-col md="6">
              <b-form-group label="Start Date">
                <b-form-datepicker v-model="filter.dateFrom" reset-button></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="End Date">
                <b-form-datepicker v-model="filter.dateTo" reset-button></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-btn class="mx-auto" type="submit" variant="primary">Generate Excel File</b-btn>
        </b-form>
      </b-overlay>
    </b-card>
  </div>
</template>
